.home-border {
	margin: 5px;
	border: 3px solid black;
	border-radius: 5px;
	animation: slidein 1s;
	min-height: 90vh;
}

@keyframes slidein {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0%);
	}
}
.home-container {
	display: grid;
	grid-gap: 5px;
	grid-template-columns: 0.2fr repeat(4, 0.4fr) 0.2fr;
	grid-template-rows: repeat(2, 40px) 40px 50px;
	padding: 5px;
	margin: 5px;
}

.home-title {
	grid-row: 1 / 3;
	grid-column: 3 / 5;
	background-color: #4056a1;
	line-height: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 0.5rem;
}

.home-subtitle {
	grid-row: 4;
	grid-column: 2 / 6;
	background-color: #4056a1;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-weight: 700;
}

.home-batman {
	grid-row: 1 / 3;
	grid-column: 2;
}

.home-bazinga {
	grid-row: 1 / 3;
	grid-column: 5;
}

.home-linked {
	grid-row: 3;
	grid-column: 5;
	display: flex;
	justify-content: center;
}
.home-git {
	grid-row: 3;
	grid-column: 2;
	display: flex;
	justify-content: center;
}

.home-title,
.home-subtitle,
.home-batman,
.home-about,
.home-boom,
.home-apt,
.home-bazinga {
	border-radius: 5px;
	border: 2px solid black;
}

.issues-div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 5px 5px 5px 5px;
	border-radius: 5px;
	text-decoration: none;
	border: 2px solid black;
	background-color: black;
	width: 35%;
}
.hero-card {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	margin: 10px 5px 0px 5px;
	border-radius: 5px;
	padding-bottom: 100px;
}

.about-div {
	grid-row: 5;
	grid-column: 2;
}

.projects-div {
	grid-row: 5;
	grid-column: 3;
}

.resume-div {
	grid-row: 6;
	grid-column: 2;
}

.contact-div {
	grid-row: 6;
	grid-column: 3;
}

.card-img {
	border-radius: 5px;
	border-bottom: 2px solid black;
	height: 180px;
}

.card-text {
	font-size: 0.6rem;
}
@media (min-width: 800px) {
	.home-border {
		margin: 5px 10px;
		border: 3px solid black;
		margin: 5px;
	}
	.home-container {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: 0.2fr repeat(5, 0.15fr) 0.2fr;
		grid-template-rows: repeat(4, 50px);
		height: 100%;
	}

	.home-title {
		display: flex;
		justify-content: space-around;
		grid-row: 1 / 3;
		grid-column: 3 / 6;
		line-height: 1.6rem;
	}
	h1,
	h2 {
		margin: auto;
	}

	.home-subtitle {
		grid-row: 4;
		grid-column: 2 / 7;
	}

	.home-batman {
		grid-row: 1 / 3;
		grid-column: 2;
	}

	.home-bazinga {
		grid-row: 1 / 3;
		grid-column: 6;
	}

	.home-linked {
		grid-row: 3;
		grid-column: 6;
	}
	.home-git {
		grid-row: 3;
		grid-column: 2;
	}

	.vol-div {
		grid-row: 8;
		grid-column: 3 / 6;
	}

	.issues-div {
		display: flex;
		justify-content: space-between;
		border-radius: 5px;
		text-decoration: none;
		border: 2px solid black;
		background-color: black;
		width: 250px;
	}
	.hero-card {
		display: grid;
		grid-template-columns: repeat(5, 0.2fr);
		margin: 10px 5px 0px 5px;
		border-radius: 5px;
		padding-bottom: 100px;
	}

	.about-div {
		grid-row: 5;
		grid-column: 2;
		padding-bottom: 10px;
	}

	.projects-div {
		grid-row: 5;
		grid-column: 4;
		padding-bottom: 10px;
	}

	.resume-div {
		grid-row: 6;
		grid-column: 2;
		padding-bottom: 10px;
	}

	.contact-div {
		grid-row: 6;
		grid-column: 4;
		padding-bottom: 10px;
	}

	.card-img {
		height: 300px;
		border-radius: 5px;
		border-bottom: 2px solid black;
	}
	.card-text {
		text-align: center;
		line-height: 1.3rem;
	}
}
