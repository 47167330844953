.project-page-container {
	padding: 5px;
	border-radius: 5px;
}
.project-subtitle {
	display: flex;
	flex-direction: row;
	text-align: center;
	justify-content: center;
	align-items: center;
	margin: 20px 0px;
	text-decoration: none;
}

.home-subtitle {
	text-decoration: none;
}

.project-card {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	margin: 10px 5px 0px 5px;
	border-radius: 5px;
	padding-bottom: 100px;
	animation: slidein 1s;
}

.project-card-img {
	height: 180px;
}

.project-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid white;
	margin: 10px;
	border-radius: 5px;
	padding-bottom: 20px;
	animation: slidein 1s;
}

.info-card {
	margin-top: 10px;
	width: 300px;
}

.project {
	font-family: "Fredericka the Great", cursive;
	letter-spacing: 0.2rem;
	color: #efe2ba;
	font-family: "Red Rose", cursive;
	margin: 10px;
}

.learn-more {
	text-decoration: none;
	color: #efe2ba;
	font-family: "Red Rose", cursive;
	border: 2px solid white;
	padding: 10px;
	margin-top: 10px;
}

.project-titles {
	font-size: 1.3rem;
}

@media (min-width: 800px) {
	.project-page-container {
		padding: 5px;
		border-radius: 5px;
		margin: 5px 10px;
	}
	.about-subtitle {
		grid-column: 2 / 7;
	}

	.project-subtitle {
		display: flex;
		flex-direction: row;
		text-align: center;
		margin: 30px 0px;
		text-decoration: none;
	}

	.home-subtitle {
		text-decoration: none;
		grid-column: 2 / 7;
	}

	.project-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border: 1px solid white;
		margin: 10px;
		border-radius: 5px;
		padding-bottom: 20px;
		margin: 10px;
	}

	.project-card {
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
		margin: 10px 5px 0px 5px;
		border-radius: 5px;
		padding-bottom: 100px;
	}
	.info-card {
		margin-top: 10px;
	}
	.project-card-img {
		height: 300px;
	}

	.project {
		font-family: "Fredericka the Great", cursive;
		letter-spacing: 0.2rem;
		color: #efe2ba;
		font-family: "Red Rose", cursive;
		margin: 10px;
	}

	.learn-more {
		text-decoration: none;
		color: #efe2ba;
		font-family: "Red Rose", cursive;
		border: 2px solid white;
		padding: 10px;
		margin-top: 10px;
	}

	.project-titles {
		font-size: 1.3rem;
	}
}
