.resume-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	animation: slidein 1s;
}
.resume-card {
	object-fit: fill;
	width: 50%;

	border: 2px solid black;
	border-radius: 5px;
}

.resume-icons {
	display: flex;
	flex-direction: row;
	width: 80%;
	height: 10%;
}

.resume-titles {
	color: #efe2ba;
	font-family: "Red Rose", cursive;
	font-size: 1rem;
	font-weight: 700;
}

.resume-list-container {
	display: flex;
	flex-direction: row;
}

.resume-list {
	display: flex;
	flex-direction: column;
	/* flex-wrap: wrap; */
	/* justify-content: space-around; */
	/* height: 100px; */
	/* list-style-type: none; */
	color: #efe2ba;
	margin: 15px;
}

@media (min-width: 800px) {
	.resume-card {
		width: 400px;
		height: 500px;
	}

	.resume-icons {
		width: 550px;
		justify-content: space-around;
		margin: 20px 0px;
	}
	.resume-icons a {
		padding: 5px;
	}

	.resume-titles {
		font-size: 2rem;
		margin: 20px 0px;
	}

	.resume-list {
		font-size: 1.3rem;
	}
}
