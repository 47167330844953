* {
	box-sizing: border-box;
	transition: ease all 0.5s;
	max-width: 1400px;
}

html {
	background-color: #f13c20;
}

img {
	object-fit: fill;
	height: 100%;
	width: 100%;
	border-radius: 4px;
}
h1 {
	font-size: 1rem;
	letter-spacing: 0.2rem;
	font-family: "Red Rose", cursive;
}
h2 {
	font-size: 0.75rem;
	font-family: "Red Rose", cursive;
}
h1,
h2 {
	color: #efe2ba;
}
.welcome-container {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 0.2fr repeat(5, 0.8fr) 0.2fr;
	grid-template-rows: 80px 20px 20px 60px repeat(3, 100px);
	border-radius: 5px;
	height: 90vh;
	padding: 5px;
	margin: 10px;
}

.start-reading,
.date-div,
.project1-div,
.project2-div,
.project3-div,
.cents-div,
.welcome-title,
.welcome-subtitle,
.welcome-batman,
.welcome-about,
.welcome-boom,
.welcome-apt,
.welcome-bazinga {
	border-radius: 6px;
	border: 2px solid black;
}
.welcome-title {
	grid-row: 1 / 2;
	grid-column: 3 / 6;
	background-color: #4056a1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.welcome-subtitle {
	grid-row: 4;
	grid-column: 2 / 7;
	background-color: #4056a1;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.welcome-batman {
	grid-row: 1 / 2;
	grid-column: 2;
}

.welcome-about {
	grid-row: 6 / 8;
	grid-column: 5 / 7;
}

.welcome-boom {
	grid-row: 5 / 7;
	grid-column: 2 / 4;
}
.welcome-apt {
	grid-row: 7;
	grid-column: 2;
}
.welcome-bazinga {
	grid-row: 1 / 2;
	grid-column: 6;
}
.vol-div {
	grid-row: 8;
	grid-column: 2 / 7;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 80px;
}
.project2-div {
	grid-row: 7;
	grid-column: 4;
}
.project1-div {
	grid-column: 4;
	grid-row: 6 / 7;
}

.project3-div {
	grid-column: 3;
	grid-row: 7;
}

.start-reading {
	text-decoration: none;
	color: black;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	text-align: center;
	font-family: "Red Rose", cursive;
	grid-row: 5;
	grid-column: 4 / 7;
	animation-name: background-animation;
	animation-delay: 6s;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

.linked {
	display: flex;
	justify-content: center;
	grid-row: 2;
	grid-column: 6;
	height: 50px;
}
.git {
	display: flex;
	justify-content: center;
	grid-row: 2;
	grid-column: 2;
	height: 50px;
}

@keyframes background-animation {
	0% {
		background-color: white;
	}
	35% {
		background-color: lightgreen;
	}
	65% {
		background-color: lightgreen;
	}
	100% {
		background-color: white;
	}
}

@media (min-width: 800px) {
	.welcome-container {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: 0.2fr repeat(5, 0.2fr) 0.2fr;
		grid-template-rows: repeat(2, 50px) 50px 50px repeat(3, 200px) 40px;
		height: 100%;
		margin: 10px;
	}
	h1 {
		font-size: 1.6rem;
		letter-spacing: 0.3rem;
		font-family: "Red Rose", cursive;
	}
	h2 {
		font-size: 1.2rem;
		font-family: "Red Rose", cursive;
	}

	.welcome-title {
		grid-row: 1 / 3;
		grid-column: 3 / 6;
	}

	.welcome-batman {
		grid-row: 1 / 3;
		grid-column: 2;
	}

	.welcome-bazinga {
		grid-row: 1 / 3;
		grid-column: 6;
	}

	.start-reading {
		font-size: 2rem;
	}

	.linked {
		display: flex;
		justify-content: center;
		grid-row: 3;
		grid-column: 6;
		height: 50px;
	}
	.git {
		display: flex;
		justify-content: center;
		grid-row: 3;
		grid-column: 2;
		height: 50px;
	}

	.vol-div {
		grid-row: 8;
		grid-column: 2 / 7;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-end;
		text-align: center;
	}
	.project2-div {
		grid-row: 7;
		grid-column: 4;
	}
	.project1-div {
		grid-column: 4;
		grid-row: 6 / 7;
	}

	.project3-div {
		grid-column: 3;
		grid-row: 7;
	}

	.welcome-about {
		grid-row: 6 / 8;
		grid-column: 5 / 7;
	}

	.welcome-boom {
		grid-row: 5 / 7;
		grid-column: 2 / 4;
	}
	.welcome-apt {
		grid-row: 7;
		grid-column: 2;
	}

	.welcome-subtitle {
		grid-column: 2 / 7;
	}
	.start-reading {
		grid-column: 4 / 7;
	}
}
