.contact-border {
	border-radius: 5px;
	margin: 5px 10px;
	padding: 5px;
	animation: slidein 1s;
}
.contact-headers {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #efe2ba;
	font-family: "Red Rose", cursive;
	margin: 5px;
	margin-bottom: -30px;
}

.sent-reply {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.sub-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 0px;
	color: #efe2ba;
	font-family: "Red Rose", cursive;
	border-top: 2px solid black;
	font-weight: 900;
	margin: 10px;
}

.vol-contact {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.contact-body {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 700px;
	margin-top: -40px;
}

.message-input,
.email-input,
.name-input {
	margin: 10px 0px;
}

.send-btn {
	color: #efe2ba;
	font-family: "Red Rose", cursive;
	background-color: #4056a1;
	padding: 10px;
	border-radius: 5px;
	width: 80px;
}

@media (min-width: 800px) {
	.contact-headers {
		margin-bottom: -40px;
	}

	.sent-reply {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.sub-form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px 0px;
		color: #efe2ba;
		font-family: "Red Rose", cursive;
		border-top: 2px solid black;
		font-weight: 900;
		margin: 20px;
	}

	.vol-contact {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.contact-body {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		height: 700px;
		margin-top: -40px;
	}

	.name-input,
	.email-input {
		height: 30px;
	}

	.message-input,
	.email-input,
	.name-input {
		margin: 10px 0px;
		width: 400px;
	}

	.send-btn {
		color: #efe2ba;
		font-family: "Red Rose", cursive;
		font-size: 1.2rem;
		background-color: #4056a1;
		border-radius: 5px;
		border: 2px solid black;
	}
}
