.about-subtitle {
	text-decoration: none;
	grid-row: 4;
	grid-column: 2 / 6;
	background-color: #4056a1;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 5px;
	border: 2px solid black;
}

.details-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid white;
	margin: 10px;
	padding: 10px;
	border-radius: 5px;
	animation: slidein 1s;
}

.details {
	font-family: "Fredericka the Great", cursive;
	letter-spacing: 0.2rem;
	line-height: 1rem;
	margin: 5px;
}

.info-card {
	border: 2px solid white;
	height: 350px;
	width: 200px;
}

.card-title {
	font-family: "Rye", cursive;
}

.about-p {
	color: #efe2ba;
	font-family: "Red Rose", cursive;
	font-size: 0.85rem;
	font-weight: 700;
}

.about-title {
	text-align: center;
	font-size: 2rem;
}

@media (min-width: 800px) {
	.details-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		border: 1px solid white;
		margin: 50px;
		margin-bottom: 100px;
		padding: 10px;
		border-radius: 5px;
	}

	.details {
		font-family: "Fredericka the Great", cursive;
		letter-spacing: 0.2rem;
		line-height: 1rem;
		margin: 30px 70px;
	}

	.info-card {
		border: 2px solid white;
		height: 350px;
		width: 250px;
	}

	.card-title {
		font-family: "Rye", cursive;
	}

	.about-p {
		color: #efe2ba;
		font-family: "Red Rose", cursive;
		font-size: 1rem;
		font-weight: 700;
		line-height: 1.4rem;
	}

	.about-title {
		text-align: center;
		font-size: 2rem;
		margin: 10px 0px;
	}
}
